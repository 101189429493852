import React, { Component } from "react";
import {
  Layout,
  Hero,
  NavigationBar,
  Advertising,
  Experiences,
  LoginModal,
  Map,
} from "../src/components";
import {
  getDestinations,
  getDestinationDetails,
  removeFavorite,
  addFavorite,
  getRequestLocale,
} from "../src/api";
import { injectIntl } from "react-intl";
import Router from "next/router";
import Head from "next/head";
import querystring from "querystring";
import classnames from "classnames";
import {
  isAndroid,
  isIOS,
  isBrowser
} from "react-device-detect";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, WEB_ROOT } = publicRuntimeConfig;
import { isLogged, getUserInfo } from "../src/utils/auth";
import { withAppState } from "../src/contexts/app-state";
import styles from "../src/components/Search/styles.css";
import stylesMap from "../src/components/Map/styles.css";
import HomeNavBar from "../src/components/HomeNavBar/HomeNavBar";
import Landing from "../src/components/Landing/index";
import Seo from "../src/components/Seo/index";

const getRecomendationById = (list, id) => {
  if (!id) return null;
  return list.filter((i) => i.rawId === id)[0];
};

const DEFAULT_MAP_CENTER = {
  lat: 40.4165,
  lng: -3.70256,
};

class Index extends Component {
  constructor(props) {
    super(props);

    const recommendation = getRecomendationById(
      props.results.items,
      props.recommendationId
    );

    this.state = {
      categoryRawId: props.categoryRawId,
      opened: true,
      showMap: false,
      defaultZoom: 11,
      center: recommendation
        ? { lat: recommendation.latitude, lng: recommendation.longitude }
        : DEFAULT_MAP_CENTER,
      items: props.results.items,
      selected: props.recommendationId,
      options: props.options,
    };
  }
  loginModal = React.createRef();
  contentMap = React.createRef();
  static async getInitialProps(ctx) {
    const { asPath, req } = ctx;
    var path = asPath;
    var autoopen = false;
    if(asPath.indexOf('?') !== -1){
      path = asPath.substring(0, asPath.indexOf('?'));
      const qs = querystring.parse(asPath.split(/\?/)[1])
      autoopen = qs.aopen;
    }
    const pathParams = path.split("/");
    const categoryRawId = pathParams[3] || "";
    const locale = await getRequestLocale(req, path);
    const destination = await getDestinationDetails(pathParams[2], locale);
    const destinations = await getDestinations(locale);
    const user = await getUserInfo(ctx);

    let items = [];

    destination.categories.map((c) => {
      items = items.concat(c.items);
    });

    return {
      user,
      pathname: path,
      lang: locale,
      destination,
      destinations,
      categoryRawId,
      results: { items: items },
      showCityPage: pathParams.length > 2,
      autoopen: autoopen
    };
  }
  translate = (id) => {
    return this.props.intl.formatMessage({ id });
  };
  selectItem(item) {
    if (item.rawId == this.state.selected) {
      Router.push(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/${item.categoryRawId}/${item.subcategoryRawId}/${item.rawId}`
      );
      return;
    }
    this.scrollTo(item.rawId);
    this.setState({
      selected: item.rawId,
      center: {
        lat: item.latitude,
        lng: item.longitude,
      },
      defaultZoom: 16,
    });
  }
  scrollTo = (i) => {
    const index = `pin-${i}`;
    if (this.refs[index]) {
      this.refs[index].scrollIntoView({
        inline: "center",
        block: "start",
        behavior: "auto",
      });
    }
  };
  renderCategory = (category) => {
    let see_more = true;
    if (category.items.length === 0) {
      return "";
    }
    return (
      <Experiences
        swipeable={see_more}
        see_more={see_more}
        href={`/${this.props.intl.locale}/${this.props.destination.rawId}/${category.rawId}`}
        title={category.name}
        categoryRawId={category.rawId}
        description={category.descriptionShort}
        highlights={category.highlights}
        data={category.items}
        isReccomended={true}
        forYou={true}
        destination={this.props.destination}
      />
    );
  };
  renderWithCategories = (destination) => {
    let see_more = destination.categories.length === 0;
    return (
      <React.Fragment>
        <Experiences
          destination={destination}
          title={this.translate("sidebar.menu.for_you")}
          highlights={destination.highlights}
          description={this.translate("experiences.for_you.subtitle")}
          data={[]}
          isReccomended={true}
          forYou={true}
        />
        {(destination.categories || []).map(this.renderCategory)}
      </React.Fragment>
    );
  };
  renderContent = () => {
    return this.renderWithCategories(this.props.destination);
  };
  onSearchChanged = (text, categoryRawId) => {
    let qs = querystring.stringify({ query: text });
    Router.push(
      `/${this.props.intl.locale}/${this.props.destination.rawId}/search?${qs}`
    );
  };
  onCategoryChange = (rawId) => {
    if (rawId == "wetravelhum") {
      return null;
    }else if (rawId == "para_ti") {
      return Router.push(`/${this.props.intl.locale}/${this.props.destination.rawId}`);
    }
    this.setState({ categoryRawId: rawId }, () => {
      Router.push(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/${rawId}`
      );
    });
  };
  getCategories = () => {
    return [
      { rawId: "wetravelhum", name: ''},
      { rawId: "para_ti", name: this.translate("sidebar.menu.for_you") },
    ].concat(this.props.destination.categories);
  };
  getFilters = () => {
    return [
      {
        idFilter: 1,
        name: "Experiencia en la ciudad",
        items: [
          {
            idCategory: 1,
            idParentCategory: 1,
            name: "La primera vez que vengo",
          },
          {
            idCategory: 2,
            idParentCategory: 1,
            name: "He estado aquí más veces",
          },
          {
            idCategory: 3,
            idParentCategory: 1,
            name: "Vivo aquí",
          },
        ],
        tags: [],
      },
      {
        idFilter: 2,
        name: "Tipo de compañia",
        items: [
          {
            idCategory: 1,
            idParentCategory: 2,
            name: "Familiar",
          },
          {
            idCategory: 2,
            idParentCategory: 2,
            name: "Pareja",
          },
          {
            idCategory: 3,
            idParentCategory: 2,
            name: "Amigos",
          },
          {
            idCategory: 4,
            idParentCategory: 2,
            name: "Negocios",
          },
          {
            idCategory: 5,
            idParentCategory: 2,
            name: "Sólo",
          },
        ],
        tags: [],
      },
    ];
  };

  heartAds = () => {
    const { is_logged, token } = this.props.user;
    const { sponsored } = this.props.destination;
    if (is_logged) {
      if (sponsored.favorite) {
        removeFavorite(token, sponsored.rawId)
          .then((data) => {
            //removed item: update UI
          })
          .catch((err) => {
            alert(err);
          });
        return;
      }
      addFavorite(token, sponsored.rawId)
        .then((data) => {})
        .catch((err) => {
          alert(err);
        });
      return;
    }
    if (this.loginModal.current) this.loginModal.current.show();
  };
  onChangeMap = () => {
    this.setState({ showMap: !this.state.showMap });
  };
  onShowMobileMap = () => {
    this.onChangeMap();
    window.setTimeout(() => {
      if (this.contentMap.current !== null) {
        let defaultPostion = 181;
        if (this.contentMap.current.parentNode.offsetWidth <= 425) {
          defaultPostion = 151;
        }
        window.scrollTo(0, this.contentMap.current.offsetTop - defaultPostion);
      }
    }, 100);
  };
  renderDiscoolver() {
    return (
      <Landing></Landing>
    )
  }
  render() {
    const { destination, lang, showCityPage } = this.props;
    const { showMap, center, items, defaultZoom, selected } = this.state;

    const categories_items = this.getCategories();

    let count_results = !!this.props.destination.sponsored ? 1 : 0;
    count_results += this.props.destination.categories.reduce(
      (acc, c) => (acc += c.items.length),
      0
    );

    let { ogTitle, ogDescription, image } = this.props.destination;
    const ogImage =
      image !== undefined && image !== null ? image["ogimage"] : "";

    if (!showCityPage && APP === 'discoolver') {
      return (
        <Landing
          categoryRawId="para_ti"
          headerProps={{
            ...this.props.user,
          }}
          footerProps={{
            user: this.props.user,
          }}
          onSearchChanged={this.onSearchChanged.bind(this)}
          destinations={this.props.destinations}
          destination={this.props.destination}
        ></Landing>
      )
    }
    return (
      <Layout
        categoryRawId="para_ti"
        headerProps={{
          ...this.props.user,
        }}
        footerProps={{
          user: this.props.user,
        }}
        onSearchChanged={this.onSearchChanged.bind(this)}
        destinations={this.props.destinations}
        destination={this.props.destination}
      >
        <Seo
          config={{
            title: ogTitle,
            description: ogDescription,
            canonical: `${WEB_ROOT}/`,
            openGraph: {
              type: "website",
              url: `${WEB_ROOT}/`,
              title: ogTitle,
              description: ogDescription,
              images: [
                {
                  url: ogImage,
                },
              ],
            },
            twitter: {
              handle: "@handle",
              site: "@site",
              cardType: "summary_large_image",
            },
          }}
        />
        <Head>
          <title>
            {this.translate("home.home.info")} {this.props.destination.name} -
            Discoolver
          </title>
        </Head>

        <HomeNavBar
          ref={this.heroContent}
          locale={this.props.lang}
          destination={this.props.destination}
          destinations={this.props.destinations}
          background={this.props.destination.image}
          selected={this.state.categoryRawId || "para_ti"}
          categories={categories_items}
          onSearchChanged={this.onSearchChanged.bind(this)}
          onCategoryChange={this.onCategoryChange}
          onMapChange={this.onChangeMap}
          printMap={true}
          results={count_results}
          user={this.props.user}
          autoopen={this.props.autoopen}
        />

        {showMap && (
          <div ref={this.contentMap} style={{ position: "relative" }}>
            <div id={styles.mapViewer} className={classnames(styles.fixedView)}>
              <div className={styles.content}>
                <div className={styles.mapContainer}>
                  <Map
                    language={lang}
                    region={lang}
                    items={items}
                    zoom={defaultZoom}
                    renderItem={({ item, index }) => {
                      return (
                        <Map.Pin
                          onClick={() => {
                            this.selectItem(item);
                          }}
                          selected={item.rawId == selected}
                          key={index}
                          pin={item}
                          lat={item.latitude}
                          lng={item.longitude}
                        />
                      );
                    }}
                    onChangeMap={this.onChangeMap}
                    intl={this.props.intl}
                    center={center}
                  />
                </div>
              </div>
              <div
                style={{ position: "absolute" }}
                className={styles.resultsContainer}
              >
                {items.map((item, index) => {
                  let resultStyle = [styles.mapResult];
                  if (item.rawId == selected) {
                    resultStyle.push(styles.selected);
                  }
                  return (
                    <div
                      ref={`pin-${item.rawId}`}
                      onClick={() => this.selectItem(item)}
                      key={index}
                      className={classnames(resultStyle)}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${item.images.tab.normal})`,
                        }}
                        className={styles.image}
                      ></div>
                      <div className={styles.informations}>
                        <div className={styles.texts}>
                          <b>
                            <span>{item.name}</span>
                          </b>
                          <span>{item.descriptionShort}</span>
                        </div>
                        <div className={styles.commands}>
                          <a
                            href={`/${this.props.intl.locale}/${this.props.destination.rawId}/${item.categoryRawId}/${item.subcategoryRawId}/${item.rawId}`}
                          >
                            {this.translate("experience.item.see_more")}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {!showMap && (
          <div>
            {this.props.destination.sponsored && (
              <Advertising
                onHeartClick={this.heartAds}
                sponsoreds={this.props.destination.sponsoreds}
              />
            )}
            {this.renderContent()}
            <LoginModal
              destination={this.props.destination}
              ref={this.loginModal}
            />
            <div className={stylesMap.contentSeeMap}>
              <button
                onClick={this.onShowMobileMap}
                className={classnames(
                  stylesMap.buttonConfig,
                  stylesMap.iconMap
                )}
              >
                {this.translate("map.see.map")}
                <span></span>
              </button>
            </div>
          </div>
        )}
      </Layout>
    );
  }
}

export default injectIntl(Index);
